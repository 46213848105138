
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/auth/log-in",
      function () {
        return require("private-next-pages/auth/log-in.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/auth/log-in"])
      });
    }
  
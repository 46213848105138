import { Box, Typography } from '@mui/material';
import type { GetServerSideProps } from 'next';
import { getProviders, getSession } from 'next-auth/react';
import Router from 'next/router';
import { useEffect, useState } from 'react';

import { LogIn } from '@expertli/features/auth/components';
import SimpleLayout from '@expertli/layouts/simple-layout';

type Props = {
  providers: { id: string; name: string }[];
  callbackUrl?: string;
};

function Page(props: Props) {
  const [manual, setManual] = useState<boolean | null>(null);

  useEffect(() => {
    const oldUrl = new URL(location.href);
    const newManual = oldUrl?.searchParams?.has('manual');
    setManual(newManual);

    if (!newManual) {
      oldUrl.searchParams.append('manual', '1');
      Router.replace(oldUrl, undefined, { shallow: true });
    }
  }, []);
  //set manual flag in url to prevent page reload resubmitting OTP
  if (manual === null) return null;

  return (
    <LogIn {...props} manualSubmit={manual}>
      <Box padding={2}>
        <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'left' }}>
          Enter your email address and we&apos;ll send you a one-time passcode to securely access
          your Expertli rooms.
        </Typography>
      </Box>
    </LogIn>
  );
}

Page.getLayout = (page, props: Props) => (
  <SimpleLayout
    pageLink={{
      text: 'Sign up',
      href: props.callbackUrl ? `/sign-up?callbackUrl=${props.callbackUrl}` : '/sign-up',
    }}
  >
    {page}
  </SimpleLayout>
);
Page.getTitle = () => 'Log in to Expertli';

export const getServerSideProps: GetServerSideProps<Props> = async (context) => {
  const callbackUrl = (context.query.callbackUrl || '') as string;

  const session = await getSession(context);
  if (session) {
    return {
      redirect: {
        destination: callbackUrl || '/',
        permanent: false,
      },
    };
  }

  const providers = await getProviders();
  return {
    props: {
      callbackUrl,
      providers: Object.values(providers)
        .filter((x) => x.id !== 'email' && x.id !== 'one-time-code-verifier')
        .map((provider) => ({ id: provider.id, name: provider.name })),
    },
  };
};

export default Page;
